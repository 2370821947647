@import url('https://fonts.googleapis.com/css?family=Lato:300,400|Lora:300,400');

.menu {
  padding-top: 10px;
  border-top: 1px solid $grey-color-light;
}

h1,h2,h3,h4 {

  font-family: Lato;
}

.page-link {
  font-family: Lato;

}

.site-title, .page-link{
  font-family: Lato;
}

/*
.external {
  color: lighten(rgb(47, 47, 47), 20%);
}

.external:visited {
  color: lighten(rgb(47, 47, 47), 00%);
}
*/

.ext-link {
  //color: $brand-color;
}

.menu-list-item {

  padding-top: 1px;
  padding-bottom: 1px;
  list-style-type: none;
  border-bottom: 1px solid $grey-color-light;
}

.list-external {

  background-color: lighten($grey-color, 48%);

}
.menu-list {
  margin-left: 1em;

}

.row {
    display: flex;
}

.column {
    flex: 50%;
    //margin: auto;
}

.center {
  text-align: center;
}

#left-container {
  float: left;
  width: 300px;
  margin-right: 2em;
}


#right-container {
  float: left;
  width: 300px;
  text-align: center;
}

.home {
  overflow: hidden;
  padding-bottom: 1em;
}
